import React from "react"
import { Layout, Header } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { StaticImage } from "gatsby-plugin-image"

const Portfolio = () => {
  return (
    <Layout>
      <GatsbySeo title="Portfolio" description="Portfolio To Be On The Web" />
      <Header />
      <section className="max-w-screen-xl px-4 py-12 mx-auto text-center">
        <h2 className="mb-2 text-4xl leading-tight font-heading">Portfolio</h2>
        <div className="flex flex-wrap -mx-8">
          <a
            href="https://www.kndb.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/kndb.png"
              alt="afbeelding KNDB"
            />
            <h3 className="text-xl font-heading">
              Koninklijke Nederlandse Dambond
            </h3>
            <span>Wordpress</span>
          </a>
          <div className="p-8 border border-gray-200 md:w-1/3">
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/healthyactivities.png"
              alt="afbeelding Healthyactivities"
            />
            <h3 className="text-xl font-heading">
              Healthyactivities (tijdelijk offline)
            </h3>
            <span>Gridsome</span>
          </div>
          <a
            href="https://www.cstdenhaan.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/cstdenhaan.png"
              alt="afbeelding Craniosacraal therapie Den Haan"
            />
            <h3 className="text-xl font-heading">
              Craniosacraal therapie Den Haan
            </h3>
            <span>Gatsby</span>
          </a>
        </div>
        <div className="flex flex-wrap -mx-8">
          <a
            href="https://www.bevrijdvanangst.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/bevrijd-van-angst.png"
              alt="afbeelding Bevrijd van angst"
            />
            <h3 className="text-xl font-heading">
              Bevrijd van angst (niet meer Online)
            </h3>
            <span>Gridsome</span>
          </a>
          <a
            href="https://www.naturalpetshop.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/natural-pet-shop.png"
              alt="afbeelding Natural Pet Shop"
            />
            <h3 className="text-xl font-heading">Natural Pet Shop</h3>
            <span>Wordpress</span>
          </a>
          <a
            href="https://www.cghomeopathie.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/cghomeopathie.png"
              alt="afbeelding cg klassieke homeopathie"
            />
            <h3 className="text-xl font-heading">C.G. klassieke homeopathie</h3>
            <span>Gatsby</span>
          </a>
        </div>
        <div className="flex flex-wrap -mx-8">
          <a
            href="https://www.hondenschoolsociaalleren.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/hondenschool-sociaal-leren.png"
              alt="afbeelding Hondenschool Sociaal Leren"
            />
            <h3 className="text-xl font-heading">Hondenschool Sociaal Leren</h3>
            <span>Gridsome</span>
          </a>
          <a
            href="https://www.cultuurpleintholen.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/cultuurplein-tholen.png"
              alt="afbeelding Cultuurplein Tholen"
            />
            <h3 className="text-xl font-heading">Cultuurplein Tholen</h3>
            <span>Wordpress</span>
          </a>
          <a
            href="https://www.despettertholen.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/despettertholen-nl.jpg"
              alt="afbeelding Zwembad de Spetter Tholen"
            />
            <h3 className="text-xl font-heading">Zwembad de Spetter Tholen</h3>
            <span>Wordpress</span>
          </a>
        </div>
        <div className="flex flex-wrap -mx-8">
          <a
            href="https://www.healthydog.be"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/www-healthydog-be.jpg"
              alt="afbeelding Healthy Dog"
            />
            <h3 className="text-xl font-heading">Healthy Dog</h3>
            <span>Wordpress</span>
          </a>
          <a
            href="https://www.healthycat.be"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4"
              src="../assets/images/portfolio/www-healthycat-be.jpg"
              alt="afbeelding Healthy Cat"
            />
            <h3 className="text-xl font-heading">Healthy Cat</h3>
            <span>Wordpress</span>
          </a>
          <a
            href="https://www.vangorselparket.nl"
            className="p-8 border border-gray-200 md:w-1/3"
          >
            <StaticImage
              className="w-full mx-auto mb-4 max-h-96"
              src="../assets/images/portfolio/vangorselparket.jpg"
              alt="afbeelding Van Gorsel Parket"
            />
            <h3 className="text-xl font-heading">Van Gorsel Parket</h3>
            <span>Gatsby</span>
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default Portfolio
